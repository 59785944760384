//*// CODE //*//

//*// Inline codes
code,
kbd,
pre,
samp {
  font-family: @plone-font-family-monospace;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: @plone-code-color;
  background-color: @plone-code-bg;
  border-radius: @plone-border-radius-base;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: @plone-kbd-color;
  background-color: @plone-kbd-bg;
  border-radius: @plone-border-radius-small;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
}

//*// Block code
pre {
  display: block;
  padding: round(((@plone-line-height-computed - 1) / 2));
  margin: 0 0 (@plone-line-height-computed / 2);
  font-size: (@plone-font-size-base - 1); // 14px to 13px
  line-height: @plone-line-height-base;
  word-break: break-all;
  word-wrap: break-word;
  color: @plone-pre-color;
  background-color: @plone-pre-bg;
  border: 1px solid @plone-pre-border-color;
  border-radius: @plone-border-radius-base;

  // Account for some code outputs that place code tags in pre tags, not used in Plone as default
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}