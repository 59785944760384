h1 {
  hyphens: auto;
}

h2 {
  font-weight: 700;
  hyphens: auto;
  @media screen and (max-width: 991px) {
    font-size: 25px;
  }
  &:after {
    height: 5px;
    background-color: @secondary;
    content: "";
    width: 81px;
    display: block;
    margin-top: 25px;
  }
}

h3 {
  font-weight: 700;
}
@media screen and (max-width: 991px){
  * {
    -ms-hyphens: auto;
    hyphens: auto;
  }
}
// Modules customization

@media screen and (min-width: 992px){
  .gradient-helper {
    position: absolute;
    width: 1120px;
    max-width: 100%;
    top: -1px;
    z-index: 0;
    box-shadow:  0px -24px 14px 6px rgb(0 0 0 / 8%);
    height: 190px;
    left: 50%;
    transform: translateX(-50%);
  }
}

#content-core .module__header {
  padding-top: 0;
  position: relative;

  &.module {
    &--topleft img {
      object-position: top left;
    }
    &--topcenter img {
      object-position: top center;
    }
    &--topright img {
      object-position: top right;
    }
    &--bottomleft img {
      object-position: bottom left;
    }
    &--bottomright img {
      object-position: bottom center;
    }
    &--bottomright img {
      object-position: bottom right;
    }
  }
  img {
    width: 100%;
    min-height: 400px;
    max-height: 650px;
    object-fit: cover;
    object-position: top;
  }

  .module {
    &__content {
      background-color: #fff;
      max-width: 1120px;
      margin-top: -85px;
      margin-bottom: 0;
      padding-top: 60px;
      padding-bottom: 0;
      padding-left: 60px;
      padding-right: 60px;
      width: 100%;
      position: relative;
      z-index: 1;

      h1 {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: @primary;
        position: relative;
        z-index: 1;
        @media screen and (min-width: 992px) {
          font-size: 3.4375rem;
        }
        span {
            color: @secondary;
        }
      }
    }
  }
}

footer#portal-footer-wrapper {
  font-family: @plone-font-family-sans-serif;
  color: #fff;

  .copyright {
    margin-top: 0px;
    font-size: .9375rem;

    hr {
      margin-bottom: 3px;
    }
  }

  .doormat {
    display: flex;
    justify-content: center;
    font-weight: 700;
  }

  .footerportlet {
    max-width: 798px;
    padding-top: 37px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 992px){
      justify-content: space-between;
      flex-direction: row;
      align-items: inherit;
    }
  }
}

// Preheaderviewlet
.preheader {
  display: flex;
  justify-content: flex-end;

  ul {
    list-style-type: none;
    display: flex;

    li {
      margin-left: 19px;
      font-size: 15px;

      a {
        color: @font;
      }
    }
  }
}

#content-core .button {
  background-color: @primary;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 10px;
  display: inline-block;
  padding: 12px 20px;
  min-width: 270px;
  text-align: center;
  transition: all ease .2s;
  border-bottom: none;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: darken(@primary, 10%);
  }
}


.videomodalwrapper {
  max-width: 100%;

  .plone-modal-content {
    max-width: 100%;
  }

  .plone-modal-header,
  .plone-modal-footer {
    border: none !important;
    box-shadow: none;
  }

  video {
    width: 100%;
    max-height: 400px;
    height: auto;
  }
}

.stoerer a {
  background-color: @secondary;
  color: @primary;
  font-size: 1.9375rem;
  display: block;
  width: 183px;
  height: 183px;
  display: flex;
  border-radius: 100px;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none !important;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 50%);
  position: absolute;
  right: -90px;
  top: -110px;
  font-weight: 600;
  p {
    margin: 0;
  }
  &:hover {
    text-decoration: none;
  }
}

// fixed header
// @media screen and (max-width: 992px) {
  #content-header {
    position: fixed;
    z-index: 15;
    background: #fff;
    width: 100%;
  }
  .container.container--full {
    padding-top: 150px;
  }
// }

// Searchresults
.template-search {
  #content {
    padding-top: 50px;
  }

  h1.documentFirstHeading {
    margin-top: 50px;
    margin-bottom: 40px;
    border-bottom: none;
    &:after {
      height: 5px;
      background-color: @secondary;
      content: "";
      width: 81px;
      display: block;
      margin-top: 25px;
    }
  }

  #searchform {
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 990px;
    }

    @media (min-width: 1200px){
        width: 1170px;
    }

    .autotabs {
      border: none;
      box-shadow: none;
    }

    .input-group {
      display: inherit;
    }

    [type="text"] {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        float: left;
        width: 20rem;
        border: 1px solid @secondary;
        box-shadow: none;
    }
    [type="submit"] {
        display: inline-block;
        margin-left: -1px;
        vertical-align: top;
        width: auto;
        display: inline-block;
        color: #fff;
        background-color: @secondary;
        border-color: @secondary;
        text-shadow: none;
    }
  }
}

#content-header {
  transition: all ease .2s;
  &.scroll {
    .preheader ul {
      margin-bottom: 0;
    }

    #portal-header {
      margin-bottom: 0;
      transition: margin-bottom ease .1s;
    }

    #portal-logo img {
      max-width: 80%;
    }
    .plone-navbar {
      margin-bottom: 0;
      transition: margin-bottom ease .1s;
    }
    .plone-navbar-nav > li > a {
      padding-top: 0;
      transition: padding-bottom ease .1s;
    }
    #portal-searchbox {
      top: 55px;
      transition: top ease .1s;
      @media screen and (min-width: 992px) {
        top: -5px;
      }
    }
    .plone-navbar-toggle {
      margin-top: -8px;
      transition: margin-top ease .1s;
    }


    #portal-searchbox #searchDrop {
      top: -15px;
      @media screen and (min-width: 992px) {
        top: 55px;
      }
    }
  }
}

.template-job-view  #content-core {
  .module__header h1.module__title {
    font-size: 25px;
    @media screen and (min-width: 992px) {
      font-size: 1.875rem;
    }
  }
  .documentFirstHeading {
    margin: 0 auto;
    max-width: 1120px;
    padding: 0 60px;
    margin-top: 100px;
  }
  .module__job {
    padding-top: 40px;

    &--date {
      margin-bottom: 20px;
      font-weight: 700;
      color:@secondary
    }

    &--divider {
      margin-top: 40px;
      margin-bottom: 40px;

      h2 {
        font-size: 1.875rem;
        @media screen and (max-width: 991px) {
            font-size: 25px;
        }
      }
    }

    .button {
      margin: 40px auto 0;
      width: -moz-fit-content;
      width: fit-content;
      display: flex;
    }

    &__share {
      display: flex;
      justify-content: space-evenly;
      margin-top: 90px;

      button, a {
        height: 150px;
        // width: 120px;
        border: 0;
        background: transparent;
        transition: all ease .2s;
        align-items: center;
        font-size: 18px;
        color: @primary;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        text-shadow: none;

        svg {
          height: 120px;
          width: 120px;
          margin-bottom: 14px;
        }

        &:hover {
          box-shadow: none;
          text-decoration: none;
        }
      }
    }
  }
}
.template-jobs-view {

  .job-text {
    margin-bottom: 40px;
  }

  #content-core .module__content.module__job {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}


#content-core .job {
  background-color: @bright-gray;
  font-size: 1.25rem;
  padding: 20px 24px 22px;
  margin-bottom: 30px;
  position: relative;
  color: @primary;

  .flexwrapper {
    border-bottom: none;

    &:hover {
      text-decoration: none;
    }

    @media screen and (min-width:992px) {
      display: flex;
    }

    img {
      display: none;
      @media screen and (min-width:992px) {
        margin-right: 20px ;
        display: block;
      }
    }
  }

  &__title {
    font-size: 1.5625rem;
    color: @primary;
    &::after {
      display: none;
    }
  }

  &__link {
    position: absolute;
    right: 0;
    bottom: 11px;
    height: 40px;
    width: 40px;
    border-bottom: 0;
  }
}

.backToTop {
  margin-top: 80px;
  text-align: center;

  button {
    border: none;
    text-shadow: none;
    color: @primary;
    transition: all ease .2s;
  }
}

// Karriere icons, im content gesetzt
.benefitswrapper {
  @media screen and (min-width: 992px) {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.benefits {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    p {
      margin-bottom:0;
    }

    img {
      width: 90px;
      margin-right: 10px;
    }
  }
}

// quickfix edit bar add content for overflow
.plone-toolbar-top #edit-zone nav>ul li#plone-contentmenu-factories.active ul {
  max-height: 100vh;
    overflow: scroll;
    padding-bottom: 200px;
}

// Logo line Footer (content in theme/browser/overrides/Products.CMFPlone.browser.templates.footer.pt)
.footer-logos {
  position: absolute;
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: 992px) {
    flex-direction: row;
  }
  a {
    margin-bottom: 40px;
    @media (min-width: 992px) {
        margin-bottom: 0;
      }
  }
  img {
    width: auto;
    max-width: 100%;
    height: 110px;
  }
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    a {
      display: flex;
      flex: 0 0 43%;
      justify-content: space-evenly;
      img {
        height: 56px;
      }
    }
  }
}

//backend fixes for large images in folder contents
.template-folder_contents {
  .pat-datatables td img.thumb-tile {
    max-height: 30px;
  }
}

// Sonderanpassungen Titel Intraakademie
.section-intraakademie {
  h1 span{
    position: relative;
    left: -24px;
  }
}

.template-login,
.template-login-help {
  #content{
    margin-top: 100px;
    margin-bottom: 100px;

    .formHelp {
      color: #000;
    }
  }

}
