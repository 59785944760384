//*// HEADER //*//

#content-header {
    border-bottom: 3px solid @primary
}

#portal-header {
    position: relative;
    margin: 15px auto 20px;
    .clearfix(); //clear floats
}

#portal-logo {
    margin-top: -20px;
    float: left;
    z-index: 10;
    position: relative;

    img {
        max-width: 100%;
        transition: max-width ease .1s;
    }
}

#portal-languageselector {
    clear: both;
    display: inline-block;
    float: left;
    margin-right: 29px;
    padding-top: 5px;
    li {
        display: inline-block;
        margin-right: 10px;
    }
}

#portal-anontools {
    float: right;
    ul {
        padding-right: 0;
    }
}

#portal-membertools-wrapper {
	float: right;
    padding: @plone-padding-base-horizontal 0;
	ul {
        margin: 0 0 @plone-padding-base-horizontal @plone-padding-base-horizontal;
		padding-right: 0;
	}
}

//mobile search
#portal-searchbox {
    clear: left;
    margin-left: 3px;
    position: relative;
    white-space: nowrap;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 2;
    @media screen and (min-width: 992px){
      top: 10px;
    }

    .searchSection {display: none;} //non on mobile

    [type="submit"] {display: none;} //submit hidden on mobile

    label {font-size: 86.667%}

    svg {
        height: 53px;
        cursor: pointer;
        position: relative;
        right: -9px;
        top: -9px;
        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    #searchDrop {
      background-color: #fff;
      padding: 10px;
      display: none;
      position: absolute;
      right: 0;
      top: 43px;
      z-index: 2;
      width: 417px;
      @media screen and (max-width: 991px){
          width: 263px;
          left: -40px;
          right: inherit;
          position: relative;
          top: 0
      }

      @media screen and (min-width: 992px){
          width: 417px;
          top: 77px;
          border-bottom: 3px solid @primary;
          border-left: 3px solid @primary;
          border-right: 3px solid @primary;
          transition: top ease .2s;
      }
    }
}

//non mobile search
@media (min-width: 992px) {
    #portal-header {
        margin-bottom: 15px;
    }
	#portal-languageselector {
        clear: none;
        float: right;
	}
}
#portal-searchbox {
    .searchSection {display: inherit;} //yes for non mobile
    [type="text"] {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        float: left;
        width: 10rem;
        border: 1px solid @secondary;
        box-shadow: none;
        @media screen and (min-width: 992px){
            width: 20rem;
        }
    }
    [type="submit"] {
        display: inline-block;
        margin-left: -1px;
        vertical-align: top;
        width: auto;
        display: inline-block;
        color: #fff;
        background-color: @secondary;
        border-color: @secondary;
        text-shadow: none;
    }
}
