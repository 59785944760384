//*// FONTS //*//

// 400 Regular
.font-face(
    @font-path:     'font/';
	@file-name:		'libre-baskerville-v9-latin-regular';
	@font-family:	'Libre Baskerville';
	@font-weight:	400;
	@font-style:	normal;
    @local:         ~"local('Libre Baskerville'),";
);

.font-face(
    @font-path:     'font/';
	@file-name:		'libre-baskerville-v9-latin-700';
	@font-family:	'Libre Baskerville';
	@font-weight:	700;
	@font-style:	normal;
    @local:         ~"local('Libre Baskerville'),";
);
