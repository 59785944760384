
// .container--full {
//   width: 100%;
// }

#content-core .module {
  // eliminate the gab from fullwidth container
  margin-left: -15px;
  margin-right: -15px;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (min-width: 768px){
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 767px){
    padding-left: 15px;
    padding-right: 15px;
  }

  &--no-pad-top {
    padding-top:0;
  }

  &--no-pad-bot {
    padding-bottom:0;
  }

  &--no-pad {
    padding-top:0;
    padding-bottom:0;
  }

  &__content {
    max-width: 1120px;
    margin: 0 auto;

    @media screen and (min-width: 768px){
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &__header {
    position: relative;

    @media screen and (max-width: 767px){
      padding-left: 0;
      padding-right: 0;
    }

    h1 {
      margin-bottom: 30px;
    }

    .module__content {
      max-width: 937px;
      margin-top: 20px;

      @media screen and (min-width: 768px){
        margin-top: 210px;
        margin-bottom: 120px;
      }
    }
  }

  &__title {
    margin-bottom: 40px;
  }

  &--white {
    background-color: #fff;
  }

  &--grey {
    background-color: @bright-gray;
  }

  &--topleft {
    background-position: top left;
  }

  &--topright {
    background-position: top right;
  }

  &--topcenter {
    background-position: top center;
  }

  &--bottomleft {
    background-position: bottom left;
  }

  &--bottomright {
    background-position: bottom right;
  }

  &--bottomcenter {
    background-position: bottom center;
  }

  &--cover {
    background-size: cover;
  }

  &--topfull {
    background-size: 100%;
    background-position: top center;
  }

  &__download {

    h2.module__title  {
      margin-bottom: 60px;
    }

    .module__text {
      margin-bottom: 50px;
    }
  }

  &__dreiklang {

    .card__wrapper {
      @media screen and (min-width: 992px) {
        display: flex;
        justify-content: space-between;
      }
    }
    &.module--no-pad {
      margin-top: 30px;
    }
    .card__item {
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px){
        width: 300px;
        margin: 0 auto;
      }

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }

      @media (min-width: 768px){
        flex: 0 0 auto;
      }

      img {
        margin-bottom: 15px;
      }

      h2, h3 {
        margin-bottom: 26px;
      }

      .card__text {
        flex-grow: 1;
      }

      .button {
        max-width: -moz-fit-content;
        max-width: fit-content;
        margin: 0 auto;
      }
      .card__link {
        color: @primary;
        font-size: 1.5625rem;
        text-align: center;
        border: none !important;
      }
    }

    .module__text {
      margin-bottom: 60px;
    }

    &.round {
      .card__wrapper {
        justify-content: space-evenly;
      }

      .imagewrapper {
        position: relative;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        height: 330px;
        width: 330px;
        border-radius: 300px;
        background-color: @bright-gray;

        img {
          width: 100px;
          margin: 0;
          margin-top: -60px;
        }

        a {
          position: absolute;
          color: @primary;
          font-size: 1.5625rem;
          font-weight: 700;
          border-bottom: none;
          margin-top: 140px;
        }
      }

      &.module--grey .imagewrapper {
        background-color: #fff;
      }
    }
    &.square .imagewrapper {
      border-radius: 0;
    }
  }

  &__imagetext {
    .module__content {
      display: flex;
      @media screen and (min-width: 768px){
        justify-content: space-between;
      }

      h2 {
        text-align: left;
        margin-bottom: 40px;
      }

      &.imageleft {
        flex-direction: column;

        @media screen and (min-width: 768px){
          flex-direction: row-reverse;
        }

        .textwrapper {
          padding-left: 80px;
        }
      }

      &.imageright {
        flex-direction: column;

        @media screen and (min-width: 768px){
          flex-direction: row;
        }

        .textwrapper {
          padding-right: 80px;
        }
      }
    }

    .textwrapper {
      flex: 1 1 50%;
    }

    .imagewrapper {
      text-align: center;
      background-color: #fff;
      padding: 60px;
      margin-top: 40px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
    }
  }

  &__accordion {

    .accordion-wrapper {
      margin-top: 60px;
    }

    .accordion {
      background-color: @gray;
      color: #fff;
      cursor: pointer;
      padding: 10px 10px;
      text-align: left;
      margin-bottom: 0;
      margin-top: 30px;
      font-size: 1.5rem;
      transition: border-radius ease .1s;
      position: relative;
      border-radius: 5px;
      display: flex;
      @media screen and (min-width: 992px) {
        padding: 20px 40px;
      }
      .imagewrapper {
        margin-right: 20px;

        img{
          max-width: 110px;
          @media screen and (min-width: 992px) {
            max-width: 160px;
          }
        }
      }

      &:first-of-type {
        margin-top: 0;
      }

      &__title {
        margin-top: 5px;
        margin-bottom: 1px;
        font-size: 1.4rem;
        @media screen and (min-width: 992px) {
          font-size: 1.875rem;
        }
        &::after {
          display: none;
        }
      }

      &__descr {
        color: @font;
        font-size: 1.25rem;
      }

      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &::after {
          content: "";
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Down%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%231a2e68' stroke-miterlimit='10' stroke-width='4' d='M20 26l11.994 14L44 26' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
          transform: rotate(180deg);

        }
      }

      &::after {
        content: '';
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Down%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%231a2e68' stroke-miterlimit='10' stroke-width='4' d='M20 26l11.994 14L44 26' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        color: #fff;
        position: absolute;
        font-size: 80px;
        right: -1px;
        top: -2px;
        font-weight: 400;
        height: 40px;
        width: 40px;
        transition: transform ease .4s;
        @media (min-width: 992px) {
          height: 80px;
          width: 80px;
          right: 13px;
          top: 5px;
        }
      }
    }

    .panel {
      border: none;
      border-top: none;
      padding: 40px;
      background-color: white;
      display: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      h2 {
        font-size: 1.625rem;
      }
      h3 {
        font-size: 1.5rem;
      }
    }
    &.module--grey .accordion {
      background-color: #fff;
    }
  }

  &__video {
    .youtubecontainer {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 0;
      height: 0;
      overflow: hidden;
      margin-top: 60px;

      @media screen and (min-width: 768px) {
        margin-top: 130px;
      }

      iframe,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .videos {
      display: flex;
      margin-top: 50px;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .video {
        text-align: center;
        flex: 0 0 225px;
        margin: 0 auto;

        @media screen and (min-width: 768px) {
          margin: inherit;
          margin-bottom: 70px;
        }

        a {
          position: relative;
          display: flex;
          flex-direction: column;
          border: none !important;

          img {
            width: 200px;
            margin: 0 auto;
          }
        }

        svg {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        &__title {
          margin-top: 40px;
          font-size: 1.25rem;
        }

        &__text {
          color: @secondary;
          font-size: 1.25rem;
        }
      }

    }
  }

  &__text {
    .button {
      margin-top: 40px;
    }
  }

  &__contact {
    .module__content {

      iframe {
        margin-top: 36px;
        max-width: 100%;
        border: 0;
      }

      .mapwrapper {
        margin-top: 60px;
      }

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;

        &.mapleft {
          flex-direction: row-reverse;
        }

        .mapwrapper {
          margin-top: 0;
        }
      }
    }
  }

  &__banner {
    .button-container {
      margin-top: 50px;

      text-align: center;
    }

    img {
      margin-top: 50px;
    }
  }

  &__profiles {
    .module__text {
      margin-bottom: 50px;
    }

    .profiles {
      margin-left: auto;
      margin-right: auto;
      @media screen and (min-width: 510px) {
        display: flex;
        flex-wrap: wrap;
      }

      &__profile {
        display: flex;
        flex: 0 0 225px;
        text-align: center;
        flex-direction: column;
        margin-bottom: 70px;

        .title {
          font-size: 1.25rem;
          color: @primary;
          font-weight: 700;
        }
        .subtitle {
          font-size: 1.25rem;
          color: @secondary;
        }

        img {
          width: 200px;
          margin: 0 auto;
          margin-bottom: 40px;
        }
      }
    }
  }

  &__horizontalcard {

    .flexwrapper {

      background-color: @bright-gray;
      padding: 20px;

      @media (min-width: 992px){
        display: flex;
      }
    }

    &--header {
      font-weight: 700;
      margin-bottom: 0;
      border-bottom: none;
    }

    .imagewrapper {
      display: flex;
      align-items: center;
      justify-items: center;
      width: 100%;
      max-width: 200px;
      padding: 20px;
      
      a.imagewrapper {
        padding: 0;
        border-bottom: none !important;
      }

      @media (max-width: 991px)  {
        margin-bottom: 20px;
        margin-left: 20px;
      }

      img {
        margin: 0 auto;
        max-width: 160px !important;
      }
    }

    .textwrapper {
      padding-left: 20px;
      display: flex;
      justify-items: center;
      flex-direction: column;
    }

    &.module--no-pad-bot {
      margin-bottom: 30px;
    }

    &.module--no-pad {
      margin-bottom: 30px;
    }

    &.module--grey .flexwrapper {
      background-color: #fff;
    }
  }
}
